<template>
  <div class="edit-container" v-loading="loading == 'loading'">
    <el-form label-width="120px" class="edit-form" :model="formData" :rules="formRules" ref="form">
      <el-form-item label="banner名称：" prop="bannerName">
        <el-input  v-model="formData.bannerName"></el-input>
      </el-form-item>

      <el-form-item label="广告位图：" prop="thumb">
        <upload-image :cropWidth="150" :cropHeight="124" v-model="formData.thumb" class="thumb"></upload-image>
        <div class="upload-tip">尺寸：150*124，上传图片格式为jpg、png，图片大小不超过15M</div>
      </el-form-item>

      <el-form-item label="幻灯片图：" prop="image">
        <upload-image :cropWidth="350" :cropHeight="183" v-model="formData.image" class="image"></upload-image>
        <div class="upload-tip">尺寸：750*392，上传图片格式为jpg、png，图片大小不超过15M</div>
      </el-form-item>

      <el-form-item label="状态：" prop="bannerStatus">
        <el-radio v-model="formData.bannerStatus" label="OPEN" border >开启</el-radio>
        <el-radio v-model="formData.bannerStatus" label="HIDDEN" border >隐藏</el-radio>
      </el-form-item>

      <el-form-item label="链接地址：" prop="bannerLink">
        <el-input  v-model="formData.bannerLink"></el-input>
      </el-form-item>

      <el-form-item>
        <el-button type="primary" :loading="loading == 'submit'" @click="handleSubmit">提交</el-button>
      </el-form-item>
    </el-form>
  </div>
</template>

<script>
import UploadImage from '@/components/UploadImage'
import {getBannerDetail,createBanner,updateBanner} from '@/api/banner'

export default {
  components: {
    UploadImage
  },
  data() {
    const validateImage = (rule, value, callback) => {
      console.log(value)
      const thumb = this.formData.thumb
      console.log(thumb)
      if (!value  && !thumb) {
        callback(new Error('请上传广告位图或幻灯片图'))
      } else {
        callback()
      }
    }
    return {
      columnId: this.$route.meta.columnId,
      bannerId: this.$route.query.bannerId,
      formData:{},
      loading: false,
      formRules: {
        bannerName: [{ required: true, message: '请输入banner名称', trigger: 'blur' }],
        image: [{ trigger: 'blur', validator: validateImage}],
        bannerStatus: [{ required: true, message: '请选择状态', trigger: 'blur' }],
        bannerLink: [{ required: true, message: '请输入链接地址', trigger: 'blur' }]
      }
    }
  },
  created() {
    if(this.bannerId){
      this.getDetail()
      this.loading = 'loading'
    }
  },
  methods: {
    getDetail() {
       getBannerDetail({bannerId:this.bannerId}).then(res => {
        if(res.data) {
          this.formData = res.data
        } else {
          this.activityId = null
        }
        this.loading = false
      })
    },
    handleSubmit() {
      this.$refs.form.validate(async valid => {
        if(valid) {
          this.loading = 'submit'
          try {
            let bannerId
            if(this.bannerId) {
              this.formData.bannerId = this.bannerId
              await updateBanner(this.formData)
              bannerId = this.bannerId
            } else {
              this.formData.columnId = this.columnId
              await createBanner(this.formData).then(res => {
                bannerId = res.data.result
              })
            }
            this.loading = false
            this.$message({
              message: '保存成功',
              type: 'success',
              duration: 1000,
              onClose: () => {
                const path = this.$route.matched.slice(this.$route.matched.length-2, this.$route.matched.length-1)[0].path
                this.$router.push(path+'/detail?bannerId='+bannerId)
              }
            })
          } catch {
            this.loading = false
          }
        } else {
          return false
        }
      })
    }
  }
}
</script>

<style lang="scss" scoped>
/deep/ .thumb .image-uploader {
  width:150px;
  height:124px;
  line-height:124px;
}
/deep/ .image .image-uploader {
  width:350px;
  height:183px;
  line-height:183px;
}
</style>
